<template>
  <audio id="clickAudio" ref="clickAudio" preload></audio>
</template>

<script>
export default {
  data() {
    return {
      audioList: [require(`@/assets/sounds/click.mp3`)]
    }
  },
  methods: {
    playAudio () {
      this.audioList = [require(`@/assets/sounds/click.mp3`)]
      const myAudio = this.$refs.clickAudio
      myAudio.src=this.audioList[0]
      myAudio.play()
      let index = 0
      return new Promise((resolve) => {
        myAudio.onended = () => {
          console.log(index, this.audioList)
          if(index < this.audioList.length - 1){
            myAudio.src=this.audioList[index+1];
            myAudio.play()
            index++;
          } else {
            this.audioList = []
            resolve()
          }
        };
      })
      
    }
  }
}
</script>
